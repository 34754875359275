<template>
  <div class="box readme">
    项目说明
  </div>
</template>

<script>
export default {
  name: "readme",
  title: "系统说明",
};
</script>
